import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import { fetchReportChain } from '@src/store/actions/reports';
import { getFetchingReportChain, getJudgmentReportChain } from '@src/store/reducers/reports';
import Loader from '@src/components/Loader';
import { Field, Form } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import InputGroupHiddenFieldAdapter from '@src/components/input/InputGroupHiddenFieldAdapter';
import OrderContactBlock from '@src/components/orders/OrderContactBlock';
import OrderDeliveryBlock from '@src/components/orders/OrderDeliveryBlock';
import { getAccountDeliverySettings } from '@src/store/actions/accountSettings';
import { getDeliverySettings, isAccountSettingsFetching } from '@src/store/reducers/accountSettings';
import { v4 as uuid } from 'uuid';
import OrderInfoBlock from '@src/components/orders/OrderInfoBlock';
import { showErrorsIfInvalidOrPerformAction, gutter } from '@src/utils/helpers/orders';
import {
    Col,
    Row,
    Button,
    Tooltip,
} from 'antd';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import {
    requiredAtLeastOneInArray,
    hasPropertiesWithTrue,
    uniqueNamesInRequest,
    required,
} from '@src/utils/helpers/validationRules';
import { postOrder } from '@src/store/actions/orders';
import {
    prepareDataForRequest,
    getInitialNewName,
    getName,
    getInitialServiceData,
    getOriginalSearchInfo,
    getPreviousSearchInfo,
} from '@src/containers/orderServiceJudgmentContinuation/helper';
import OldNamesSection from '@src/containers/orderServiceJudgmentContinuation/OrderFormSections/OldNamesSection';
import NewNamesSection from '@src/containers/orderServiceJudgmentContinuation/OrderFormSections/NewNamesSection';
import PatriotSection from '@src/containers/orderServiceJudgmentContinuation/OrderFormSections/PatriotSection';
import WhenFieldChanges from '@src/components/input/WhenFieldChanges';
import { isIncompletedStatus } from '@src/utils/helpers/reports';

const addNewNameToForm = (values, initialNewName, push, setEditNameIndex) => {
    setEditNameIndex(values.newNames?.length || 0);
    push('newNames', initialNewName);
};
const getNameForValidation = nameItem => `[${nameItem.category}]-${getName(nameItem)}-[${nameItem.nameQualifier || ''}]`;
const getNames = ({ newNames, oldNames }) => (
    [
        ...newNames.map(getNameForValidation),
        ...oldNames.map(getNameForValidation),
    ]
);

const saveName = (setEditNameIndex, values, index, update) => {
    setEditNameIndex(null);
    const name = getName(values.newNames[index]).replace(/\s{2,}/g, ' ');
    update('newNames', index, { ...values.newNames[index], name });
};

const validateForm = data => {
    if (data) {
        const {
            areNewNamesSelected,
            areOldNamesSelected,
            isPatriotSearchSelected,
            oldNames = [],
            newNames = [],
        } = data;
        const oldNamesSelection = areOldNamesSelected
            ? oldNames.filter(({ selectedToContinue }) => selectedToContinue)
            : [];
        const newNamesSelection = areNewNamesSelected
            ? newNames.filter(({ selectedToContinue }) => selectedToContinue)
            : [];
        const patriotSearchSelection = [
            ...oldNames.filter(({ patriotSearch }) => patriotSearch),
            ...newNames.filter(({ patriotSearch }) => patriotSearch),
        ];

        const result = {
            areOldNamesSelected: areOldNamesSelected
                ? requiredAtLeastOneInArray(oldNamesSelection)
                : undefined,
            areNewNamesSelected: areNewNamesSelected
                ? requiredAtLeastOneInArray(newNamesSelection)
                : undefined,
            isPatriotSearchSelected: isPatriotSearchSelected
                ? requiredAtLeastOneInArray(patriotSearchSelection)
                : undefined,
            selectedServices: hasPropertiesWithTrue({
                areNewNamesSelected: areNewNamesSelected && !!newNames.length,
                areOldNamesSelected,
                isPatriotSearchSelected,
            })
            || requiredAtLeastOneInArray(
                [...oldNamesSelection, ...newNamesSelection],
                'name for judgment',
            ),
            newNames: newNames.map(({
                companyName,
                category,
                individualNameFirst,
                individualNameLast,
                originalSearchBegin,
                originalSearchEnd,
            }) => {
                const nameFields = category === 'I'
                    ? {
                        individualNameFirst: required(individualNameFirst),
                        individualNameLast: required(individualNameLast),
                    }
                    : { companyName: required(companyName) };
                return {
                    name: uniqueNamesInRequest(getNames({ newNames, oldNames }).map(name => name.toLowerCase())),
                    ...nameFields,
                    originalSearchBegin: required(originalSearchBegin),
                    originalSearchEnd: required(originalSearchEnd),
                };
            }),
        };

        return result;
    }
    return {};
};

const isOldOriginalReport = judgmentReportChain => {
    const originalReportOrderDate = judgmentReportChain
        && judgmentReportChain.reportList
        && judgmentReportChain.reportList[0]
        && judgmentReportChain.reportList[0].orderDate;

    return moment().subtract(1, 'years').isAfter(originalReportOrderDate);
};

const OrderForm = ({ id, setNextStep, setPrevStep }) => {
    const dispatch = useDispatch();

    const fetchingReportChain = useSelector(getFetchingReportChain);
    const judgmentReportChain = useSelector(getJudgmentReportChain);
    const deliveryInformation = useSelector(getDeliverySettings);
    const isDeliverySettingsFetching = useSelector(isAccountSettingsFetching);

    const [serviceData, setServiceData] = useState(null);
    const [editNameIndex, setEditNameIndex] = useState(null);

    useEffect(() => {
        dispatch(fetchReportChain(id));
        dispatch(getAccountDeliverySettings());
    }, []);

    useEffect(() => {
        if (!fetchingReportChain && !isDeliverySettingsFetching) {
            setServiceData(getInitialServiceData(deliveryInformation, judgmentReportChain));
        }
    }, [judgmentReportChain, deliveryInformation]);

    const originalSearchInfo = useMemo(() => getOriginalSearchInfo(judgmentReportChain), [judgmentReportChain]);

    const previousSearchInfo = useMemo(() => getPreviousSearchInfo(judgmentReportChain), [judgmentReportChain]);

    const defaultJudgmentSearchPeriod = [
        moment.utc(deliveryInformation.beginCertificationDate),
        moment.utc(deliveryInformation.lastCertificationDate),
    ];

    const hasIncompletedInChain = useMemo(
        () => judgmentReportChain?.reportList?.some(({ status }) => isIncompletedStatus(status)),
        [judgmentReportChain],
    );

    return (
        <>
            <Loader show={fetchingReportChain || isDeliverySettingsFetching}>
                {
                    serviceData
                    && (
                        <Form
                            onSubmit={data => {
                                dispatch(postOrder('judgmentContinuation', prepareDataForRequest(data)));
                                setNextStep();
                            }}
                            initialValues={serviceData}
                            mutators={{ ...arrayMutators, setFieldTouched }}
                            validate={validateForm}
                        >
                            {
                                ({
                                    handleSubmit,
                                    form,
                                    values,
                                    form: {
                                        mutators: { push, remove, update },
                                    },
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="contentHeader text-center">
                                            <h1>Judgment Continuation / Append Order Form</h1>
                                        </div>
                                        {
                                            !judgmentReportChain.continuationBranchCorrect
                                            && (
                                                <div className="error-section">
                                                    You can't continue this order. Continuation
                                                    chain isn't correct.
                                                </div>
                                            )
                                        }
                                        {
                                            isOldOriginalReport(judgmentReportChain)
                                            && (
                                                <div className="error-section">
                                                    Continuations on Judgments greater than 1 year are not permitted.
                                                </div>
                                            )
                                        }
                                        <div className="formBlock">
                                            <OrderContactBlock
                                                confirmationEmail={deliveryInformation.confirmationEmail}
                                                confirmationEmailList={deliveryInformation.confirmationEmailList}
                                                contactList={deliveryInformation.contactList}
                                            />
                                        </div>
                                        <div className="formBlock inline-form-block">
                                            <OrderDeliveryBlock
                                                deliveryPreference={values.deliveryPreference}
                                                isRushVisible={false}
                                            />
                                        </div>
                                        {
                                            originalSearchInfo.map(({ title, subTitle, content }) => (
                                                <div className="formBlock" key={uuid()}>
                                                    <OrderInfoBlock
                                                        title={title}
                                                        subTitle={subTitle}
                                                        content={content}
                                                    />
                                                </div>
                                            ))
                                        }
                                        {
                                            previousSearchInfo.map(({ title, subTitle, content }) => (
                                                <div className="formBlock" key={uuid()}>
                                                    <OrderInfoBlock
                                                        title={title}
                                                        subTitle={subTitle}
                                                        content={content}
                                                    />
                                                </div>
                                            ))
                                        }
                                        <Field
                                            name="selectedServices"
                                            component={InputGroupHiddenFieldAdapter}
                                            controlId="selectedServices"
                                        />
                                        <div className="serviceBlock formBlock">
                                            <Row className="serviceHeader">
                                                <Col md={24} lg={16}>
                                                    <Field
                                                        name="areOldNamesSelected"
                                                        component={InputGroupAntCheckBoxAdapter}
                                                        controlId="areOldNamesSelected"
                                                        labelText="JUDGMENT SEARCH CONTINUATION"
                                                        type="checkbox"
                                                        disabled
                                                    />
                                                    <WhenFieldChanges
                                                        field="oldNames"
                                                        set="areOldNamesSelected"
                                                        to
                                                    />
                                                </Col>
                                            </Row>
                                            {
                                                values.areOldNamesSelected
                                                && (
                                                    <OldNamesSection
                                                        oldNames={values.oldNames}
                                                        defaultJudgmentSearchPeriod={defaultJudgmentSearchPeriod}
                                                        setFieldValue={form.change}
                                                        gutter={gutter}
                                                    />
                                                )
                                            }
                                        </div>
                                        <div className={`serviceBlock formBlock ${values.newNames?.length ? 'visible' : 'hidden'}`}>
                                            <div className="serviceHeader">
                                                <Field
                                                    name="areNewNamesSelected"
                                                    component={InputGroupAntCheckBoxAdapter}
                                                    controlId="areNewNamesSelected"
                                                    labelText="JUDGMENT SEARCH APPEND"
                                                    type="checkbox"
                                                />
                                                <WhenFieldChanges
                                                    field="newNames"
                                                    set="areNewNamesSelected"
                                                    to={!!values.newNames?.length}
                                                />
                                            </div>
                                            <NewNamesSection
                                                newNames={values.newNames}
                                                areNewNamesSelected={values.areNewNamesSelected}
                                                serviceData={serviceData}
                                                gutter={gutter}
                                                defaultJudgmentSearchPeriod={defaultJudgmentSearchPeriod}
                                                removeNameHandler={index => {
                                                    remove('newNames', index);
                                                    setEditNameIndex(null);
                                                }}
                                                saveNameHandler={index => {
                                                    showErrorsIfInvalidOrPerformAction(
                                                        {
                                                            ...form,
                                                            getState: () => {
                                                                const formState = form.getState();
                                                                // take into account only
                                                                // newNames fields validation
                                                                return {
                                                                    ...formState,
                                                                    valid: !formState.errors.newNames.some(
                                                                        name => name && Object
                                                                            .values(name)
                                                                            .some(value => value),
                                                                    ),
                                                                };
                                                            },
                                                        },
                                                        () => saveName(
                                                            setEditNameIndex,
                                                            values,
                                                            index,
                                                            update,
                                                        ),
                                                    );
                                                }}
                                                editNameIndex={editNameIndex}
                                                setEditNameIndex={setEditNameIndex}
                                                setFieldValue={form.change}
                                            />
                                        </div>
                                        <div className="serviceBlock formBlock">
                                            <Row className="serviceHeader">
                                                <Col md={24} lg={16}>
                                                    <Field
                                                        name="isPatriotSearchSelected"
                                                        component={InputGroupAntCheckBoxAdapter}
                                                        controlId="isPatriotSearchSelected"
                                                        labelText="PATRIOT SEARCH"
                                                        type="checkbox"
                                                    />
                                                </Col>
                                            </Row>
                                            {
                                                values.isPatriotSearchSelected
                                                && (
                                                    <PatriotSection
                                                        oldNames={values.oldNames}
                                                        newNames={values.newNames}
                                                        areNewNamesSelected={values.areNewNamesSelected}
                                                        gutter={gutter}
                                                    />
                                                )
                                            }
                                        </div>
                                        <Tooltip
                                            title="It is not allowed to place an incomplete order"
                                            trigger={hasIncompletedInChain ? 'hover' : []}
                                        >
                                            <Button
                                                id="placeOrderButton"
                                                htmlType="submit"
                                                className="btn btn-primary pull-right"
                                                type="primary"
                                                disabled={
                                                    !judgmentReportChain.continuationBranchCorrect
                                                    || editNameIndex !== null
                                                    || hasIncompletedInChain
                                                }
                                            >
                                                Place Order
                                            </Button>
                                        </Tooltip>
                                        <Button
                                            className="right-buffer-xs pull-right"
                                            onClick={() => addNewNameToForm(
                                                values,
                                                getInitialNewName(defaultJudgmentSearchPeriod),
                                                push,
                                                setEditNameIndex,
                                            )}
                                            disabled={
                                                !judgmentReportChain.continuationBranchCorrect
                                                || editNameIndex !== null
                                            }
                                        >
                                            Add Name To Search
                                        </Button>
                                        <Button
                                            className="right-buffer-xs pull-right"
                                            onClick={setPrevStep}
                                        >
                                            Cancel
                                        </Button>
                                    </form>
                                )
                            }
                        </Form>
                    )
                }
            </Loader>
        </>
    );
};

export default OrderForm;
