import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';

export const orderServiceModes = {
    SELECT_FORM: 0,
    SELECT_RESULTS: 1,
    EDIT: 2,
    PREVIEW: 3,
    CONFIRMATION: 4,
};

export const initialSearchFormData = {
    block: '',
    county: '1',
    floodSearchLOLSelected: false,
    floodSearchSelected: false,
    lot: '',
    mainAddress: '',
    municipality: '',
    owner: '',
    propertyToSearchTable_length: '10',
    qualifier: '',
    taxContinuationSelected: false,
    taxSearchSelected: false,
    tidelandsSearchSelected: false,
    wetlandsSearchSelected: false,
};

export const getInitialServiceData = parentRecNo => ({
    isTaxContinuation: !!parentRecNo,
    reference: '',
    selectedProperties: [],
    selectedServicesTypes: {
        'taxSearchService': false,
        'taxContinuationService': !!parentRecNo,
        'tidelandsSearchService': false,
        'floodSearchService': false,
        'floodSearchServiceLifeOfLoan': false,
        'wetlandsSearchService': false,
    },
    selectedServices: {
        taxContinuationService: {
            comment: '',
            parentRecNo,
            serviceName: 'Tax Search Continuation',
            type: 'taxContinuationService',
        },
        taxSearchService: {
            assessment: true,
            comment: '',
            serviceName: 'Tax Search',
            type: 'taxSearchService',
            utilities: true,
        },
        tidelandsSearchService: {
            address: '',
            comment: '',
            name: '',
            needInstruments: 'YES',
            serviceName: 'Tidelands Search',
            type: 'tidelandsSearchService',
        },
        floodSearchService: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            comment: '',
            lender: '',
            lifeOfLoan: false,
            loadLength: '',
            loanId: '',
            serviceName: 'Flood Search',
            state: 'NJ',
            type: 'floodSearchService',
            zip: '',
        },
        floodSearchServiceLifeOfLoan: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            comment: '',
            lender: '',
            lifeOfLoan: true,
            loadLength: '',
            loanId: '',
            serviceName: 'Flood Search - Life Of Loan',
            state: 'NJ',
            type: 'floodSearchService',
            zip: '',
        },
        wetlandsSearchService: {
            comment: '',
            serviceName: 'Wetlands Search',
            type: 'wetlandsSearchService',
        },
    },
});

const getPropertyContentFromList = (data, municipalities, onUnselectProperty) => data.map(({
    displayBlock,
    displayLot,
    district,
    ownerName,
    recordKey,
    qualify,
    streetAddress: {
        addrLine1,
        addrLine2,
        city,
        county,
        zip,
    },
}, index) => {
    const property = [];
    property.push({
        subTitle: `#${index + 1}`,
        action: data.length > 1 ? { method: () => onUnselectProperty(recordKey), icon: <DeleteOutlined /> } : null,
    });
    if (ownerName && ownerName.length) {
        property
            .push({ label: 'Owner', value: ownerName.map(({ unformattedName }) => unformattedName).join(', ') });
    }
    if (county) {
        property.push({ label: 'County', value: county?.replace(' County', '') });
    }
    if (district) {
        property.push({ label: 'Municipality', value: getMunicipalityNameById(municipalities, district) });
    }
    if (city) {
        property.push({ label: 'City', value: city });
    }
    if (displayBlock) {
        property.push({ label: 'Block', value: displayBlock });
    }
    if (displayLot) {
        property.push({ label: 'Lot', value: displayLot });
    }
    if (qualify) {
        property.push({ label: 'Qualifier', value: qualify });
    }
    if (addrLine1) {
        property.push({ label: 'Address', value: `${addrLine1} ${addrLine2 || ''}` });
    }
    if (zip?.zip1) {
        property.push({ label: 'Zip code', value: zip.zip1 });
    }

    return property;
});

const getPropertyContentFromForm = (
    {
        block,
        county,
        lot,
        mainAddress,
        municipality,
        owner,
        qualifier,
    },
    municipalities,
    counties,
) => {
    const content = [];
    if (owner) {
        content.push({ label: 'Owner', value: owner });
    }
    if (county) {
        content.push({ label: 'County', value: getCountyNameById(counties, county) });
    }
    if (municipality) {
        content.push({ label: 'Municipality', value: getMunicipalityNameById(municipalities, municipality) });
    }
    if (block) {
        content.push({ label: 'Block', value: block });
    }
    if (lot) {
        content.push({ label: 'Lot', value: lot });
    }
    if (qualifier) {
        content.push({ label: 'Qualifier', value: qualifier });
    }
    if (mainAddress) {
        content.push({ label: 'Address', value: mainAddress });
    }

    return [content];
};

const getServicesInformation = ({
    selectedServices: {
        taxSearchService,
        taxContinuationService,
        tidelandsSearchService,
        floodSearchService,
        floodSearchServiceLifeOfLoan,
        wetlandsSearchService,
    },
    selectedServicesTypes: {
        taxSearchService: isTaxSearchService,
        taxContinuationService: isTaxContinuationService,
        tidelandsSearchService: isTidelandsSearchService,
        floodSearchService: isFloodSearchService,
        floodSearchServiceLifeOfLoan: isFloodSearchServiceLifeOfLoan,
        wetlandsSearchService: isWetlandsSearchService,
    },
}) => {
    const servicesInformation = { content: [] };
    if (isTaxSearchService) {
        servicesInformation.content.push(getTaxSearchServiceInformation(taxSearchService));
    }
    if (isTaxContinuationService) {
        servicesInformation.content.push(getTaxContinuationServiceInformation(taxContinuationService));
    }
    if (isTidelandsSearchService) {
        servicesInformation.content.push(getTidelandsSearchServiceInformation(tidelandsSearchService));
    }
    if (isFloodSearchService) {
        servicesInformation.content.push(getFloodSearchServiceInformation(floodSearchService));
    }
    if (isFloodSearchServiceLifeOfLoan) {
        servicesInformation.content.push(getFloodSearchServiceInformation(floodSearchServiceLifeOfLoan));
    }
    if (isWetlandsSearchService) {
        servicesInformation.content.push(getWetlandsSearchServiceInformation(wetlandsSearchService));
    }

    return servicesInformation;
};

const getTaxSearchServiceInformation = ({
    serviceName,
    assessment,
    utilities,
    comment,
}) => {
    const taxSearchServiceInformation = [
        { title: serviceName },
        { label: 'Unconfirmed Assessment', value: assessment ? 'YES' : 'NO' },
        { label: 'Utilities', value: utilities ? 'YES' : 'NO' },
    ];
    if (comment) taxSearchServiceInformation.push({ label: 'Comments', value: comment });

    return taxSearchServiceInformation;
};

const getTaxContinuationServiceInformation = ({
    serviceName,
    comment,
}) => {
    const taxSearchServiceInformation = [
        { title: serviceName },
    ];
    if (comment) taxSearchServiceInformation.push({ label: 'Comments', value: comment });

    return taxSearchServiceInformation;
};

const getTidelandsSearchServiceInformation = ({
    address,
    comment,
    name,
    needInstruments,
    serviceName,
}) => {
    const tidelandsSearchServiceInformation = [
        { title: serviceName },
        { label: 'Needs Instruments', value: needInstruments },
    ];
    if (name) {
        tidelandsSearchServiceInformation.push({ label: 'Issue Certificate To', value: name });
    }
    if (address) {
        tidelandsSearchServiceInformation.push({ label: 'Address', value: address });
    }
    if (comment) {
        tidelandsSearchServiceInformation.push({ label: 'Comments', value: comment });
    }

    return tidelandsSearchServiceInformation;
};

const getFloodSearchServiceInformation = ({
    addressLine1,
    addressLine2,
    city,
    comment,
    lender,
    loadLength,
    loanId,
    serviceName,
    state,
    zip,
}) => {
    const tidelandsSearchServiceInformation = [
        { title: serviceName },
    ];
    if (lender) {
        tidelandsSearchServiceInformation.push({ label: 'Lender', value: lender });
    }
    if (addressLine1) {
        tidelandsSearchServiceInformation.push({ label: 'Lender Address 1', value: addressLine1 });
    }
    if (addressLine2) {
        tidelandsSearchServiceInformation.push({ label: 'Lender Address 2', value: addressLine2 });
    }
    if (city) {
        tidelandsSearchServiceInformation.push({ label: 'Lender City', value: city });
    }
    if (state) {
        tidelandsSearchServiceInformation.push({ label: 'Lender State', value: state });
    }
    if (zip) {
        tidelandsSearchServiceInformation.push({ label: 'Lender Zip', value: zip });
    }
    if (loanId) {
        tidelandsSearchServiceInformation.push({ label: 'Loan ID', value: loanId });
    }
    if (loadLength) {
        tidelandsSearchServiceInformation.push({ label: 'Duration (years)', value: loadLength });
    }
    if (comment) {
        tidelandsSearchServiceInformation.push({ label: 'Comments', value: comment });
    }

    return tidelandsSearchServiceInformation;
};

const getWetlandsSearchServiceInformation = ({
    serviceName,
    comment,
}) => {
    const wetlandsSearchServiceInformation = [
        { title: serviceName },
    ];

    if (comment) wetlandsSearchServiceInformation.push({ label: 'Comments', value: comment });

    return wetlandsSearchServiceInformation;
};

export const getMainInformation = (
    serviceData,
    selectedProperty,
    propertyFormData,
    municipalities,
    counties,
    onUnselectProperty,
) => {
    const propertyInformation = {
        title: 'Property Information',
        content: [],
    };

    propertyInformation.content = selectedProperty.length
        ? getPropertyContentFromList(selectedProperty, municipalities, onUnselectProperty)
        : getPropertyContentFromForm(propertyFormData, municipalities, counties);

    const servicesInformation = getServicesInformation(serviceData);

    const mainInformation = [
        propertyInformation,
        servicesInformation,
    ];

    return mainInformation;
};

export const getCountyNameById = (counties, countyId) => {
    const currentCounty = counties.find(({ id }) => id === +countyId);

    return currentCounty ? currentCounty.name : '';
};

export const getCountyIdByName = (counties, countyName) => {
    const currentCounty = counties.find(({ name }) => name === countyName);

    return currentCounty ? currentCounty.id : null;
};

export const getMunicipalityNameById = (municipalities, municipalityId) => {
    let municipalityName = '';

    if (
        municipalities
        && Object.values(municipalities).length
        && municipalityId
    ) {
        // eslint-disable-next-line no-unused-expressions
        Object.values(municipalities).find(county => county.find(({ id, name }) => {
            if (id === +municipalityId) {
                municipalityName = name;
                return true;
            }
            return false;
        }))[0].name;
    }

    return municipalityName;
};

export const getMunicipalityIdByName = (municipalities, municipalityName) => {
    const flatMunicipalities = [].concat(...Object.values(municipalities));
    const municipality = flatMunicipalities.find(({ name }) => name === municipalityName);

    return municipality ? municipality.id : null;
};

export const prepareDataForRequest = (
    servicesData,
    deliveryData,
    selectedProperties,
    propertyCriteria,
    municipalities,
    counties,
    isTaxContinuation,
) => {
    const {
        confirmationEmail,
        contactId,
        deliveryPreference: {
            additionalEmail = '',
            email,
            emailResults,
            webDelivery,
        },
    } = deliveryData;

    const {
        reference,
        selectedServices,
        selectedServicesTypes,
    } = servicesData;
    const {
        block,
        county,
        lot,
        mainAddress,
        municipality: municipalityId,
        owner,
        // eslint-disable-next-line camelcase
        propertyToSearchTable_length,
        qualifier,
    } = propertyCriteria;

    return {
        confirmationEmail,
        contactId,
        deliveryPreference: {
            additionalEmail,
            email,
            emailResults,
            webDelivery,
        },
        isTaxContinuation,
        propertyCriteria: selectedProperties.length
            ? {}
            : {
                block,
                county: getCountyNameById(counties, county),
                lot,
                mainAddress,
                municipality: getMunicipalityNameById(municipalities, municipalityId),
                municipalityId,
                owner,
                propertyToSearchTable_length,
                qualifier,
            },
        reference,
        selectedProperties,
        selectedServices: Object.keys(selectedServicesTypes)
            .filter(serviceName => selectedServicesTypes[serviceName])
            .map(serviceName => selectedServices[serviceName]),
    };
};

export const searchSuppliers = {
    'NONE': 'None',
    'PRIOR': 'Priority Search Services',
    'WEST': 'Western Technologies Group',
};
