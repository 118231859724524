const departmentsArray = [
    {
        department: 'Abstract',
        key: 'ABS',
        types: [
            { text: 'Case Status Report', key: 'ABC440,ABC440S' },
            { text: 'Full Chancery Abstract', key: 'AB440,AB440S' },
            { text: 'Short Form Chancery Abstract', key: 'ABS440,ABS440S' },
            { text: 'Miscellaneous', key: 'AM460' },
        ],
    },
    {
        department: 'Corporation',
        key: 'CORP',
        types: [
            { text: 'Annual Report', key: 'AN420,AN420S,CLA420,CLA420S,CLAC420' },
            { text: 'Availability', key: 'AP420,AV420,AVLLC420' },
            { text: 'Copy Work', key: 'CA420,CAC420,CAN420,CANC420,CD420,CLP420,CLPA420,CLPC420,CNPCA420,COM420,CRA420,CTN420,CTRADE420' },
            { text: 'Copy of Authority', key: 'CAUTH420,CAUTHC420,CCA420,CCAA420,CCAC420' },
            { text: 'Corporation Filling', key: 'FALP420,FAUTH420,FAUTHC420,FCA420,FCAC420,FCAN420,FCANC420,FCC420,FCCC420,FCCO420,FCCOC420,FCD420,FCDC420,FCF420,FCFC420,FCI420,FCIC420,FCLP420,FCLPC420,FCM420,FCMC420,FCWM420,FCWMC420,FR420,FRALT420,FRALTC420,FRCI420,FRCIC420,RF420,RNR420,UF1410,UF2410,UF410' },
            { text: 'Certificate of Cancellation', key: 'CCAN420,CCANC420' },
            { text: 'Certificate of Formation', key: 'CF420,CF420S,CFA420,CFA420S,CFC420,CFCA420' },
            { text: 'Certificate of Incorporation', key: 'CI420,CI420S,CIA420,CIA420S,CIAC420,CIAC420S,CIC420,CIC420S,CICA420' },
            { text: 'Certificate of Merger', key: 'CM420,CMC420' },
            { text: 'Change of Registered Agent & Office', key: 'CRA420,CRO420,CROA420' },
            { text: 'Entity Fillings (All Reports)', key: 'CW420,CW420S,CWA420,CWA420,CWC420,CWC420S,CWCA420,CWCA420S' },
            { text: 'Good Standing Certificate', key: 'GS420,GSC420,GSC420S,GSL420,GSL420S,GSLLC420,GSLLC420S,GSLLLC420,GSLLLC420S,OGS420' },
            { text: 'Entity Status', key: 'S420,S420S' },
            { text: 'Franchise Tax Report', key: 'T420,T420S' },
            { text: 'Litigation Search', key: 'OL460' },
            { text: 'Miscellaneous', key: 'CMISC420' },
            { text: 'Officers & Directors', key: 'OD420,OD420S,ROD420,SOD420' },
            { text: 'Out of State Judgment Search', key: 'OSJ460' },
            { text: 'Registered Agent', key: 'RA420,RA420S' },
            { text: 'UCC Search', key: 'U410,U410S,OUC410' },
        ],
    },
    {
        department: 'Judgment',
        key: 'JDG',
        types: [
            { text: 'Child Support Search', key: 'CS500,CS500S,CSP500' },
            { text: 'Child Support Status', key: 'CSS500,CSSJ500' },
            { text: 'Judgment Search', key: 'JS500,JS500S,JSR500,JSR500S,JSP500,JSP500S,JSRP500,JSRP500S' },
            { text: 'Litigation Search', key: 'LIT445' },
            { text: 'Miscellaneous Judgments', key: 'JSC500,BK450,JSD500,JSRL500,JSM500,JSW500' },
            { text: 'Patriot Search', key: 'PS200,PS200S,PS500,PS500S' },
        ],
    },
    {
        department: 'Property Searches',
        key: 'PROP',
        types: [
            { text: 'Flood Life of Loan Search', key: 'ZWTG006,ZPSS300' },
            { text: 'Flood Search', key: 'ZWTG002,ZPSS200' },
            { text: 'Tax Continuation', key: 'ZPSS400' },
            { text: 'Tax Search', key: 'ZPSS100' },
            { text: 'Tideland Search', key: 'ZWTG004' },
            { text: 'Wetlands Search', key: 'ZWTG010' },
        ],
    },
    {
        department: 'Foreclosure/Miscellaneous',
        key: 'MISC_FC',
        types: [
            { text: 'Foreclosure', key: 'FL430' },
            { text: 'Miscellaneous', key: 'MD460,M460' },
        ],
    },
];

export default departmentsArray;
