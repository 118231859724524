export const FETCHING_REPORTS = 'FETCHING_REPORTS';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAIL = 'FETCH_REPORTS_FAIL';
export const FETCHING_REPORT_CHAIN = 'FETCHING_REPORT_CHAIN';
export const FETCH_REPORT_CHAIN_SUCCESS = 'FETCH_REPORT_CHAIN_SUCCESS';
export const FETCH_REPORT_CHAIN_FAIL = 'FETCH_REPORT_CHAIN_FAIL';
export const FETCHING_TAX_CONTINUATION_PROPERTY = 'FETCHING_TAX_CONTINUATION_PROPERTY';
export const FETCH_TAX_CONTINUATION_PROPERTY_SUCCESS = 'FETCH_TAX_CONTINUATION_PROPERTY_SUCCESS';
export const FETCH_TAX_CONTINUATION_PROPERTY_FAIL = 'FETCH_TAX_CONTINUATION_PROPERTY_FAIL';
export const FETCHING_PROPERTY = 'FETCHING_PROPERTY';
export const FETCH_PROPERTY_SUCCESS = 'FETCH_PROPERTY_SUCCESS';
export const FETCH_PROPERTY_FAIL = 'FETCH_PROPERTY_FAIL';
export const RESET_PROPERTY = 'RESET_PROPERTY';
export const RESET_TAX_CONTINUATION_PROPERTY = 'RESET_TAX_CONTINUATION_PROPERTY';
export const COMBINING_REPORTS = 'COMBINING_REPORTS';
export const COMBINE_REPORTS_SUCCESS = 'COMBINE_REPORTS_SUCCESS';
export const COMBINE_REPORTS_FAIL = 'COMBINE_REPORTS_FAIL';
export const DELETE_COMBINING_REPORTS = 'DELETE_COMBINING_REPORTS';
export const DELETE_ALL_COMBINING_REPORTS = 'DELETE_ALL_COMBINING_REPORTS';
export const RESENDING_REPORTS_BY_EMAIL = 'RESENDING_REPORTS_BY_EMAIL';
export const RESEND_REPORTS_BY_EMAIL_SUCCESS = 'RESEND_REPORTS_BY_EMAIL_SUCCESS';
export const RESEND_REPORTS_BY_EMAIL_FAIL = 'RESEND_REPORTS_BY_EMAIL_FAIL';
export const REMOVING_REPORTS_FROM_NEW = 'REMOVING_REPORTS_FROM_NEW';
export const REMOVE_REPORTS_FROM_NEW_SUCCESS = 'REMOVE_REPORTS_FROM_NEW_SUCCESS';
export const REMOVE_REPORTS_FROM_NEW_FAIL = 'REMOVE_REPORTS_FROM_NEW_FAIL';
export const EXPORT_CSV = 'EXPORT_CSV';
export const EXPORT_CSV_SUCESS = 'EXPORT_CSV_SUCESS';
export const EXPORT_CSV_FAILED = 'EXPORT_CSV_FAILED';
export const SELECT_ALL_JUDGMENT_CONTINUATION_REPORTS = 'SELECT_ALL_JUDGMENT_CONTINUATION_REPORTS';
export const REMOVE_REPORT_BY_ID = 'REMOVE_REPORT_BY_ID';

export const fetchingReports = (type, isSilent) => ({
    type: FETCHING_REPORTS,
    reportsType: type,
    isSilent,
});

export const fetchReportsSucceeded = (response, type) => ({
    type: FETCH_REPORTS_SUCCESS,
    reportsType: type,
    response,
});

export const fetchReportsFailed = (error, type) => ({
    type: FETCH_REPORTS_FAIL,
    reportsType: type,
    error,
});

export const fetchingReportChain = () => ({
    type: FETCHING_REPORT_CHAIN,
});

export const fetchReportChainSucceeded = response => ({
    type: FETCH_REPORT_CHAIN_SUCCESS,
    response,
});

export const fetchReportChainFailed = error => ({
    type: FETCH_REPORT_CHAIN_FAIL,
    error,
});

export const fetchingProperty = () => ({
    type: FETCHING_PROPERTY,
});

export const fetchPropertySucceeded = response => ({
    type: FETCH_PROPERTY_SUCCESS,
    response,
});

export const fetchPropertyFailed = error => ({
    type: FETCH_PROPERTY_FAIL,
    error,
});

export const resetProperty = () => ({
    type: RESET_PROPERTY,
});

export const resetTaxContinuationProperty = () => ({
    type: RESET_TAX_CONTINUATION_PROPERTY,
});

export const fetchingTaxContinuationProperty = () => ({
    type: FETCHING_TAX_CONTINUATION_PROPERTY,
});

export const fetchTaxContinuationPropertySucceeded = response => ({
    type: FETCH_TAX_CONTINUATION_PROPERTY_SUCCESS,
    response,
});

export const fetchTaxContinuationPropertyFailed = error => ({
    type: FETCH_TAX_CONTINUATION_PROPERTY_FAIL,
    error,
});

export const combiningReports = ids => ({
    type: COMBINING_REPORTS,
    ids,
});

export const combineReportsSucceeded = (ids, type, url) => ({
    type: COMBINE_REPORTS_SUCCESS,
    reportsType: type,
    ids,
    url,
});

export const combineReportsFailed = (error, ids) => ({
    type: COMBINE_REPORTS_FAIL,
    error,
    ids,
});

export const deleteCombiningReports = ids => ({
    type: DELETE_COMBINING_REPORTS,
    ids,
});

export const deleteAllCombiningReports = () => ({
    type: DELETE_ALL_COMBINING_REPORTS,
});

export const resendingReportsByEmail = () => ({
    type: RESENDING_REPORTS_BY_EMAIL,
});

export const resendReportsByEmailSucceeded = response => ({
    type: RESEND_REPORTS_BY_EMAIL_SUCCESS,
    response,
});

export const resendReportsByEmailFailed = error => ({
    type: RESEND_REPORTS_BY_EMAIL_FAIL,
    error,
});

export const removingReportsFromNew = () => ({
    type: REMOVING_REPORTS_FROM_NEW,
});

export const removeReportsFromNewSucceeded = (ids, type) => ({
    type: REMOVE_REPORTS_FROM_NEW_SUCCESS,
    reportsType: type,
    ids,
});

export const removeReportsFromNewFailed = error => ({
    type: REMOVE_REPORTS_FROM_NEW_FAIL,
    error,
});

export const exportCsv = () => ({
    type: EXPORT_CSV,
});

export const exportCsvSuccess = response => ({
    type: EXPORT_CSV_SUCESS,
    response,
});

export const exportCsvFailed = error => ({
    type: EXPORT_CSV_FAILED,
    error,
});

export const setSelectAllJudgmentContinuationReports = isSelected => ({
    type: SELECT_ALL_JUDGMENT_CONTINUATION_REPORTS,
    isSelected,
});

export const removeReportById = (id, type) => ({
    type: REMOVE_REPORT_BY_ID,
    reportsType: type,
    id,
});
