import React from 'react';
import { Col, Row } from 'antd';
import { Field } from 'react-final-form';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import { composeValidators, required } from '@src/utils/helpers/validationRules';
import { disabledDateByActivePeriod } from '@src/utils/helpers/dateUtils';
import { FAQS_NAME_QUALIFIER } from '@src/utils/constants/routerConstants';
import InputGroupAntDatePickerAdapter from '@src/components/input/InputGroupAntDatePickerAdapter';
import AdditionalStatesField from '@src/containers/orderServiceNameSearch/AdditionalStatesField';
import InfoLink from '@src/components/InfoLink';

const getStatesWithFederalLocal = states => states
    .map(({ title, key }) => (
        key === 'NJ'
            ? [
                { title, key },
            ]
            : [
                { title: `${title} - Fed`, key: `${key} - Fed` },
                { title: `${title} - Local`, key: `${key} - Local` },
            ]
    ))
    .reduce((acc, val) => acc.concat(val), []);

const ServiceJudgmentSearch = ({
    name,
    values,
    index,
    gutter,
    states,
    nameQualifierOptions,
    defaultJudgmentSearchPeriod,
    setFieldValue,
}) => (
    <div className="serviceBlock formBlock">
        <Row className="serviceHeader">
            <Col md={24} lg={16}>
                <Field
                    name={`${name}.selectedServicesTypes.judgmentSearch`}
                    component={InputGroupAntCheckBoxAdapter}
                    controlId={`${name}.selectedServicesTypes.judgmentSearch`}
                    labelText="JUDGMENT SEARCH"
                    type="checkbox"
                />
            </Col>
        </Row>
        {
            values.namesToSearch[index].selectedServicesTypes.judgmentSearch
            && (
                <>
                    <Row gutter={gutter}>
                        <Col md={12} lg={8}>
                            <Field
                                name={`${name}.selectedServices.judgmentSearch.from`}
                                component={InputGroupAntDatePickerAdapter}
                                controlId={`${name}.selectedServices.judgmentSearch.from`}
                                labelText="From"
                                validate={composeValidators(required)}
                                disabledDate={current => disabledDateByActivePeriod(current, [
                                    defaultJudgmentSearchPeriod[0],
                                    values.namesToSearch[index].selectedServices.judgmentSearch.to
                                    || defaultJudgmentSearchPeriod[1],
                                ])}
                                defaultPickerValue={defaultJudgmentSearchPeriod[0]}
                                isRequired
                                showToday={false}
                                onChange={date => (
                                    date
                                        ? setFieldValue(`${name}.selectedServices.judgmentSearch.from`, date)
                                        : setFieldValue(
                                            `${name}.selectedServices.judgmentSearch.from`,
                                            defaultJudgmentSearchPeriod[0],
                                        )
                                )}
                            />
                        </Col>
                        <Col md={12} lg={8}>
                            <Field
                                name={`${name}.selectedServices.judgmentSearch.to`}
                                component={InputGroupAntDatePickerAdapter}
                                controlId={`${name}.selectedServices.judgmentSearch.to`}
                                labelText="To"
                                validate={composeValidators(required)}
                                disabledDate={current => disabledDateByActivePeriod(current, [
                                    values.namesToSearch[index].selectedServices.judgmentSearch.from
                                    || defaultJudgmentSearchPeriod[0],
                                    defaultJudgmentSearchPeriod[1],
                                ])}
                                defaultPickerValue={defaultJudgmentSearchPeriod[1]}
                                isRequired
                                showToday={false}
                                onChange={date => (
                                    date
                                        ? setFieldValue(`${name}.selectedServices.judgmentSearch.to`, date)
                                        : setFieldValue(
                                            `${name}.selectedServices.judgmentSearch.to`,
                                            defaultJudgmentSearchPeriod[1],
                                        )
                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={gutter}>
                        <Col md={24} lg={16}>
                            <Field
                                name={`${name}.selectedServices.judgmentSearch.nameQualifier`}
                                component={InputGroupAntSelectAdapter}
                                controlId={`${name}.selectedServices.judgmentSearch.nameQualifier`}
                                labelText={(
                                    <InfoLink
                                        text="Name Qualifier"
                                        href={FAQS_NAME_QUALIFIER}
                                        tooltip="How to use name qualifiers"
                                    />
                                )}
                                options={nameQualifierOptions}
                            />
                        </Col>
                    </Row>
                    <Row gutter={gutter}>
                        <Col md={24} lg={24}>
                            <Field
                                name={`${name}.selectedServices.judgmentSearch.exactName`}
                                component={InputGroupAntCheckBoxAdapter}
                                controlId={`${name}.selectedServices.judgmentSearch.exactName`}
                                labelText="Exact Name Only"
                                type="checkbox"
                            />
                        </Col>
                    </Row>
                    <hr />
                    <AdditionalStatesField
                        name={name}
                        serviceName="judgmentSearch"
                        values={values}
                        index={index}
                        gutter={gutter}
                        states={getStatesWithFederalLocal(states)}
                    />
                    {
                        values.namesToSearch[index].selectedServices.judgmentSearch.additionalStates
                        && (
                            <>
                                <hr />
                                <Row gutter={gutter}>
                                    <Col md={24} lg={16}>
                                        <Field
                                            name={`${name}.selectedServices.judgmentSearch.addressLine1`}
                                            component={InputGroupAntInputAdapter}
                                            controlId={`${name}.selectedServices.judgmentSearch.addressLine1`}
                                            labelText="Address Line 1"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={24} lg={16}>
                                        <Field
                                            name={`${name}.selectedServices.judgmentSearch.addressLine2`}
                                            component={InputGroupAntInputAdapter}
                                            controlId={`${name}.selectedServices.judgmentSearch.addressLine2`}
                                            labelText="Address Line 2"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={24} lg={8}>
                                        <Field
                                            name={`${name}.selectedServices.judgmentSearch.city`}
                                            component={InputGroupAntInputAdapter}
                                            controlId={`${name}.selectedServices.judgmentSearch.city`}
                                            labelText="City"
                                        />
                                    </Col>
                                    <Col md={24} lg={8}>
                                        <Field
                                            name={`${name}.selectedServices.judgmentSearch.zip`}
                                            component={InputGroupAntInputAdapter}
                                            controlId={`${name}.selectedServices.judgmentSearch.zip`}
                                            labelText="Zip"
                                        />
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                </>
            )
        }
    </div>
);

export default ServiceJudgmentSearch;
