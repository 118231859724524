import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Row, Col } from 'antd';
import OrderConfirmation from '@src/components/orders/OrderConfirmation';
import OrderPreview from '@src/components/orders/OrderPreview';
import { getAccountDeliverySettings } from '@src/store/actions/accountSettings';
import { getAllStates } from '@src/store/actions/states';
import { postOrder, downloadConfirmation } from '@src/store/actions/orders';
import {
    getDeliverySettings,
    isAccountSettingsFetching,
} from '@src/store/reducers/accountSettings';
import { getStates } from '@src/store/reducers/states';
import { getPostedOrder, getPosting, getError } from '@src/store/reducers/orders';
import { getCurrentCustomer } from '@src/store/reducers/customers';
import Loader from '@src/components/Loader';
import {
    searchTypeOptions,
    uccSearchTypeOptions,
    countyOptions,
    companyTypeOptions,
    nameQualifierOptions,
    goodStndTypeOptions,
    copiesOfEntityFilingsTypeOptions,
} from '@src/utils/templates/orderServicesOptions';
import {
    getContactInformationConfirmation,
    getOrderInformationConfirmation,
    getDeliveryInformationConfirmation,
    getMainInformationConfirmation,
} from '@src/utils/helpers/orders';
import OrderNameSearchForm from './OrderNameSearchForm';
import {
    defaultSelectedState,
    getInitialServiceData,
    getOrderInformation,
    getMainInformation,
    prepareDataForRequest,
    getInitialDataFromPreviousOrder,
} from './helper';

const orderServiceModes = {
    EDIT: 'edit',
    PREVIEW: 'preview',
    CONFIRMATION: 'confirmation',
};

const OrderServiceNameSearchContent = () => {
    const { EDIT, PREVIEW, CONFIRMATION } = orderServiceModes;

    const deliveryOptions = useSelector(getDeliverySettings);
    const isDeliverySettingsFetching = useSelector(isAccountSettingsFetching);
    const isPostingOrder = useSelector(getPosting);
    const postedOrder = useSelector(getPostedOrder);
    const postedOrderError = useSelector(getError);
    const states = useSelector(getStates);
    const { number: currentCustomerId } = useSelector(getCurrentCustomer);

    const [mode, setMode] = useState(EDIT);
    const [deliveryData, setDeliveryData] = useState(deliveryOptions);
    const { lastCertificationDate, beginCertificationDate, copySelectedServices } = deliveryOptions;
    const defaultFormData = useMemo(
        () => getInitialServiceData(beginCertificationDate, lastCertificationDate, defaultSelectedState),
        [beginCertificationDate, lastCertificationDate],
    );

    const [serviceData, setServiceData] = useState(
        getInitialServiceData(beginCertificationDate, lastCertificationDate, defaultSelectedState),
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (currentCustomerId) {
            dispatch(getAccountDeliverySettings());
            dispatch(getAllStates());
            setMode(EDIT);
        }
    }, [currentCustomerId]);

    useEffect(() => {
        setDeliveryData(deliveryOptions);
        setServiceData(defaultFormData);
    }, [deliveryOptions]);

    return (
        <Loader className="delivery-settings-loader" show={isDeliverySettingsFetching}>
            <Row id="orderServiceNameSearchContainer">
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
                    {
                        mode === EDIT
                        && (
                            <OrderNameSearchForm
                                setNextStep={data => {
                                    setMode(PREVIEW);
                                    setServiceData({
                                        ...serviceData,
                                        ...data,
                                    });
                                }}
                                formData={serviceData}
                                defaultFormData={defaultFormData}
                                isReferenceRequired={deliveryData.referenceRequired}
                                defaultJudgmentSearchPeriod={[
                                    moment.utc(beginCertificationDate),
                                    moment.utc(lastCertificationDate),
                                ]}
                                searchTypeOptions={searchTypeOptions}
                                companyTypeOptions={companyTypeOptions}
                                uccSearchTypeOptions={uccSearchTypeOptions}
                                countyOptions={countyOptions}
                                nameQualifierOptions={nameQualifierOptions}
                                goodStndTypeOptions={goodStndTypeOptions}
                                copiesOfEntityFilingsTypeOptions={copiesOfEntityFilingsTypeOptions}
                                states={states.map(({ state, stateDesc }) => ({ title: stateDesc, key: state }))}
                            />
                        )
                    }
                    {
                        mode === PREVIEW
                        && (
                            <OrderPreview
                                setNextStep={() => setMode(CONFIRMATION)}
                                setPrevStep={() => setMode(EDIT)}
                                onSubmitOrder={data => {
                                    dispatch(postOrder('nameSearch', { ...prepareDataForRequest(serviceData), ...data }));
                                    setDeliveryData(deliveryOptions);
                                }}
                                setDeliveryData={setDeliveryData}
                                orderInformation={getOrderInformation(serviceData)}
                                deliveryInformation={deliveryData}
                                mainInformation={getMainInformation(serviceData)}
                                serviceData={serviceData}
                                header="Name Search / Corporate Order Preview"
                            />
                        )
                    }
                    {
                        mode === CONFIRMATION
                        && (
                            <OrderConfirmation
                                setNextStep={() => {
                                    if (copySelectedServices) {
                                        setServiceData(getInitialDataFromPreviousOrder(serviceData, defaultFormData));
                                    } else {
                                        setServiceData(defaultFormData);
                                    }
                                    setMode(EDIT);
                                }}
                                downLoadConfirmation={orderId => dispatch(downloadConfirmation(orderId))}
                                isPostingOrder={isPostingOrder}
                                postedOrderError={postedOrderError}
                                order={postedOrder}
                                orderInformation={getOrderInformationConfirmation(postedOrder)}
                                contactInformation={getContactInformationConfirmation(postedOrder)}
                                deliveryInformation={getDeliveryInformationConfirmation(postedOrder)}
                                ordersList={getMainInformationConfirmation(postedOrder)}
                            />
                        )
                    }
                </Col>
            </Row>
        </Loader>
    );
};

export default OrderServiceNameSearchContent;
