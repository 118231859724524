export const customerTableColumns = [
    'custid', 'custName', 'priceCode', 'city', 'creditHold',
    'region', 'postalCode',
    'nickName',
];

export const completedReportsTableColumns = [
    'id', 'reqdate', 'refno', 'webno', 'text', 'status', 'smItem.description',
    'pages', 'donedate', 'state', 'url', 'newRptFlag', 'type',
];

export const openOrdersTableColumns = [
    'id', 'reqdate', 'refno', 'webno', 'text', 'status',
    'smItem.description', 'state', 'type',
];

export const judgmentContinuationOrdersTableColumns = ['id', 'donedate', 'refno', 'orderno',
    'text', 'smItem.description', 'pages', 'status', 'origno', 'url', 'prevno',
];

export const taxContinuationOrdersTableColumns = [
    'id', 'donedate', 'refno', 'orderno', 'text', 'smItem.description', 'pages', 'url',
];
