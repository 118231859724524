import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Col, Row, Button } from 'antd';
import { gutter } from '@src/utils/helpers/orders';
import { Field, Form, FormSpy } from 'react-final-form';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import createDecorator from 'final-form-focus';
import { composeValidators, hasPropertiesWithTrue, required } from '@src/utils/helpers/validationRules';
import {
    getCountyNameById,
    getMunicipalityNameById,
    searchSuppliers,
} from '@src/containers/orderServicePropertySearch/helper';
import TaxContinuationSection from '@src/containers/orderServicePropertySearch/OrderFormSections/TaxContinuationSection';
import TaxSearchSection from '@src/containers/orderServicePropertySearch/OrderFormSections/TaxSearchSection';
import TidelandsSearchSection from '@src/containers/orderServicePropertySearch/OrderFormSections/TidelandsSearchSection';
import FloodSearchSection from '@src/containers/orderServicePropertySearch/OrderFormSections/FloodSearchSection';
import WetlandsSearchSection from '@src/containers/orderServicePropertySearch/OrderFormSections/WetlandsSearchSection';
import InputGroupHiddenFieldAdapter from '@src/components/input/InputGroupHiddenFieldAdapter';
import Loader from '@src/components/Loader';

const focusOnError = createDecorator();

const additionalInfo = (block, lot, qualifier) => {
    const value = [
        (block ? `block:  ${block}` : ''),
        (lot ? `lot:  ${lot}` : ''),
        (qualifier ? `qualifier:  ${qualifier}` : '')]
        .filter(val => val && val.trim()).join(', ');

    return value ? ` (${value})` : '';
};

const getPropertyListItemFromForm = (
    {
        block,
        county,
        lot,
        mainAddress,
        municipality,
        owner,
        qualifier,
    },
    municipalities,
    counties,
) => {
    const countyName = getCountyNameById(counties, county);
    const municipalityName = getMunicipalityNameById(municipalities, municipality);

    return `${mainAddress}, ${municipalityName} ( ${countyName} )${owner ? ` + ${owner}` : ''}${additionalInfo(block, lot, qualifier)}`;
};

const getPropertyListItemFromSelectedProperty = ({
    streetAddress,
    ownerName,
    displayBlock,
    displayLot,
    qualify,
}) => (
    `${streetAddress.addrLine1}, ${streetAddress.city} (
        ${streetAddress.county?.replace(' County', '')} ) + ${ownerName ? ownerName.map(name => name.unformattedName).join(', ') : ''}
        ${additionalInfo(displayBlock, displayLot, qualify)}`
);

const RefNoField = ({ isReferenceRequired }) => {
    const container = document.getElementsByClassName('reference-field-portal');

    return (
        container.length
            ? ReactDOM.createPortal(
            // eslint-disable-next-line jsx-a11y/interactive-supports-focus,jsx-a11y/control-has-associated-label
                <div
                    onKeyDown={() => {}}
                    className="formBlock"
                    key="reference-field-portal-child"
                    role="link"
                    onClick={e => e.stopPropagation()}
                >
                    <Row gutter={gutter}>
                        <Col md={24} lg={16}>
                            <Field
                                name="reference"
                                component={InputGroupAntInputAdapter}
                                controlId="reference"
                                labelText="Reference Number"
                                validate={
                                    isReferenceRequired
                                    && composeValidators(required)
                                }
                                isRequired={isReferenceRequired}
                            />
                        </Col>
                    </Row>
                </div>,
                container[0],
            )
            : ''
    );
};

const scrollToFieldWithError = () => {
    setTimeout(() => {
        const el = document.querySelector('.has-error');
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
        }
    }, 10);
};

const SelectServicesForm = ({
    goToSearchStep,
    goToPreviewStep,
    selectedProperty,
    searchPropertyFormData,
    municipalities = [],
    counties = [],
    states = [],
    formData = {},
    propertySearchSettings = {},
    isReferenceRequired,
    setData,
    setForm,
    customerName,
    isContinuation,
    isPropertyLoading,
    previewOrderButtonRef,
    isVisible,
}) => (
    <Loader show={isPropertyLoading}>
        <h3>Properties on Order</h3>
        <div className="formBlock" id="properties-on-order-block">
            <ul>
                {
                    !selectedProperty.length
                        ? <li>{getPropertyListItemFromForm(searchPropertyFormData, municipalities, counties)}</li>
                        : selectedProperty.map(
                            item => (
                                <li key={item.recordKey}>{getPropertyListItemFromSelectedProperty(item)}</li>
                            ),
                        )
                }
            </ul>
        </div>
        <Form
            onSubmit={args => {
                setData(args);
                goToPreviewStep();
            }}
            decorators={[focusOnError]}
            initialValues={formData}
            validate={({ selectedServicesTypes }) => {
                const selectedServicesTypesError = hasPropertiesWithTrue(selectedServicesTypes);

                return {
                    selectedServicesTypes: selectedServicesTypesError && <>{selectedServicesTypesError}</>,
                };
            }}
        >
            {
                params => {
                    const {
                        handleSubmit,
                        form,
                        values,
                    } = params;

                    const [isFormSpyActive, setIsFormSpyActive] = useState(false);

                    useEffect(() => {
                        if (isVisible) {
                            const timeout = setTimeout(() => setIsFormSpyActive(isVisible), 1000);
                            return () => clearTimeout(timeout);
                        }

                        setIsFormSpyActive(false);
                        return () => {};
                    }, [isVisible]);

                    useEffect(() => {
                        setForm(form);
                    }, []);

                    return (
                        <form onSubmit={handleSubmit}>
                            <RefNoField
                                isReferenceRequired={isReferenceRequired}
                            />
                            {
                                isFormSpyActive && (
                                    <FormSpy
                                        subscription={{ submitFailed: true }}
                                        onChange={scrollToFieldWithError}
                                    />
                                )
                            }
                            <Field
                                name="selectedServicesTypes"
                                component={InputGroupHiddenFieldAdapter}
                                controlId="selectedServicesTypes"
                            />
                            {
                                isContinuation
                                    ? (
                                        <TaxContinuationSection
                                            searchSupplier={searchSuppliers[propertySearchSettings.taxSearchSupplier]}
                                            values={values}
                                        />
                                    )
                                    : (
                                        <TaxSearchSection
                                            searchSupplier={searchSuppliers[propertySearchSettings.taxSearchSupplier]}
                                            values={values}
                                        />
                                    )
                            }
                            <TidelandsSearchSection
                                searchSupplier={searchSuppliers[propertySearchSettings.tidelandSearchSupplier]}
                                values={values}
                                customerName={customerName}
                            />
                            <FloodSearchSection
                                searchSupplier={searchSuppliers[propertySearchSettings.floodSearchSupplier]}
                                values={values}
                                states={states}
                            />
                            <WetlandsSearchSection
                                searchSupplier={searchSuppliers[propertySearchSettings.wetlandsSearchSupplier]}
                                values={values}
                                states={states}
                            />
                            <Button
                                htmlType="button"
                                className="btn btn-default"
                                onClick={goToSearchStep}
                            >
                                Previous
                            </Button>
                            <Button
                                id="preview-order-button"
                                ref={previewOrderButtonRef}
                                htmlType="submit"
                                type="primary"
                                className="btn btn-primary pull-right"
                            >
                                Preview Order
                            </Button>
                        </form>
                    );
                }
            }
        </Form>
    </Loader>
);

export default SelectServicesForm;
