import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Row, Col, Button } from 'antd';
import InputGroupAntRangePickerAdapter from '@src/components/input/InputGroupAntRangePickerAdapter';
import InputGroupAntTagBoxAdapter from '@src/components/input/InputGroupAntTagBoxAdapter';
import InputGroupAntTreeSelectAdapter from '@src/components/input/InputGroupAntTreeSelectAdapter';
import InputGroupWithMatchTypeChooser from '@src/components/input/InputGroupWithMatchTypeChooser';
import {
    composeValidators,
    onlyIntegersInArray,
} from '@src/utils/helpers/validationRules';
import createDecorator from 'final-form-focus';
import departmentsArray from '@src/utils/templates/departments';
import { integersMatchTypeOptions } from '@src/utils/helpers/devExpressHelpers';
import { CaretDownFilled } from '@ant-design/icons';
import { gutter } from '@src/utils/helpers/orders';

const productsArray = departmentsArray.map(({ types }) => types.map(({ key }) => key)).flat();

const focusOnError = createDecorator();

const parseDepartments = departments => departments.map(item => ({
    title: <span data-test-treenode-value={`${item.key}.title`}>{item.department}</span>,
    value: item.key,
    key: item.key,
    switcherIcon: <span data-test-treenode-value={`${item.key}.arrow`}><CaretDownFilled className="ant-select-tree-switcher-icon" /></span>,
    children: item.types.map(type => ({
        title: <span data-test-treenode-value={`${type.key}.title`}>{type.text}</span>,
        value: type.key,
        key: type.key,
        isLeaf: true,
    })),
}));

const getFields = (filterNames, form) => {
    const fields = {
        refno: <InputGroupWithMatchTypeChooser
            className="reference-numbers-field"
            key="refno"
            fieldName="refno"
            labelText="Reference Number(s)"
            component={InputGroupAntTagBoxAdapter}
            autoFocus
            maxTagTextLength={25}
            formSubmit={form.submit}
            virtual={false}
        />,
        donedate: <Field
            className="done-date-field"
            name="donedate.value"
            key="donedate"
            component={InputGroupAntRangePickerAdapter}
            controlId="donedate.value"
            labelText="Completed Date"
        />,
        text: <InputGroupWithMatchTypeChooser
            className="keywords-field"
            key="text"
            fieldName="text"
            labelText="Keyword(s)"
            component={InputGroupAntTagBoxAdapter}
            formSubmit={form.submit}
            virtual={false}
        />,
        type: <Field
            className="report-types-field"
            name="type.value"
            key="type"
            component={InputGroupAntTreeSelectAdapter}
            controlId="type.value"
            labelText="Report Type(s)"
            treeData={parseDepartments(departmentsArray)}
            placeholder="Select"
            format={value => (
                value.map(key => (
                    productsArray.find(productsKeys => (
                        new RegExp(`^${key}(,|$)`).test(productsKeys)
                    ))
                )).filter(Boolean)
            )}
            parse={value => value.map(item => item.split(',')).flat()}
            virtual={false}
        />,
        orderno: <InputGroupWithMatchTypeChooser
            className="order-numbers-field"
            key="orderno"
            fieldName="orderno"
            labelText="Order Number(s)"
            component={InputGroupAntTagBoxAdapter}
            validate={composeValidators(onlyIntegersInArray)}
            tokenSeparators={[' ', ',']}
            formSubmit={form.submit}
            virtual={false}
        />,
        id: <InputGroupWithMatchTypeChooser //reportNo
            key="id"
            fieldName="id"
            className="report-numbers-field"
            labelText="Report Number(s)"
            component={InputGroupAntTagBoxAdapter}
            validate={composeValidators(onlyIntegersInArray)}
            tokenSeparators={[' ', ',']}
            matchTypeOptions={integersMatchTypeOptions}
            formSubmit={form.submit}
        />,
    };

    const numberFirstColumnFields = Math.ceil(filterNames.length / 2);
    const firstColumnFields = filterNames.slice(0, numberFirstColumnFields);
    const secondColumnFields = filterNames.slice(numberFirstColumnFields);

    return (
        <>
            <Col md={12} xs={24} className="top-buffer-xs">
                {firstColumnFields.map(name => fields[name])}
            </Col>
            <Col md={12} xs={24} className="top-buffer-xs">
                {secondColumnFields.map(name => fields[name])}
            </Col>
        </>
    );
};

const ReportsFilter = ({
    isDisabled,
    onSubmit,
    onClearFilters = () => {},
    initialValues,
    filterFields,
    additionalValueForFilter,
    clearFormButtonRef,
}) => (
    <div className="filters well">
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            decorators={[focusOnError]}
            mutators={{ ...arrayMutators }}
        >
            {
                ({
                    handleSubmit, form, invalid, values,
                }) => {
                    useEffect(() => {
                        const { name, value } = additionalValueForFilter;
                        if (name && value && !values[name].value.includes(value)) {
                            form.mutators.push(`${name}.value`, value);
                        }
                    }, [additionalValueForFilter]);

                    return (
                        <form onSubmit={handleSubmit}>
                            <Row gutter={gutter}>
                                <Col md={24}>
                                    <Row gutter={gutter}>
                                        {getFields(filterFields, form)}
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={gutter}>
                                <Col md={24} xs={24}>
                                    <Button
                                        id="findReports"
                                        className="btn btn-primary"
                                        htmlType="submit"
                                        type="primary"
                                        disabled={isDisabled || invalid}
                                    >
                                        Find
                                    </Button>
                                    <Button
                                        ref={clearFormButtonRef}
                                        value="Clear Search"
                                        id="cancelChanges"
                                        className="cancel btn btn-default left-buffer-xs"
                                        htmlType="button"
                                        onClick={() => {
                                            onClearFilters();
                                            form.batch(() => {
                                                Object
                                                    .keys(initialValues)
                                                    .map(field => form.change(field, initialValues[field]));
                                            });
                                        }}
                                        disabled={isDisabled}
                                    >
                                        Clear Search
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    );
                }
            }
        </Form>
    </div>
);

export default ReportsFilter;
